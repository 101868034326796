exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-favorites-js": () => import("./../../../src/pages/favorites.js" /* webpackChunkName: "component---src-pages-favorites-js" */),
  "component---src-pages-gists-access-latencies-js": () => import("./../../../src/pages/gists/access-latencies.js" /* webpackChunkName: "component---src-pages-gists-access-latencies-js" */),
  "component---src-pages-gists-index-js": () => import("./../../../src/pages/gists/index.js" /* webpackChunkName: "component---src-pages-gists-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pg-essays-js": () => import("./../../../src/pages/pg/essays.js" /* webpackChunkName: "component---src-pages-pg-essays-js" */),
  "component---src-pages-pg-index-js": () => import("./../../../src/pages/pg/index.js" /* webpackChunkName: "component---src-pages-pg-index-js" */),
  "component---src-pages-writings-developers-hate-jira-js": () => import("./../../../src/pages/writings/developers-hate-jira.js" /* webpackChunkName: "component---src-pages-writings-developers-hate-jira-js" */),
  "component---src-pages-writings-does-altruism-really-exist-js": () => import("./../../../src/pages/writings/does-altruism-really-exist.js" /* webpackChunkName: "component---src-pages-writings-does-altruism-really-exist-js" */),
  "component---src-pages-writings-ideal-software-js": () => import("./../../../src/pages/writings/ideal-software.js" /* webpackChunkName: "component---src-pages-writings-ideal-software-js" */),
  "component---src-pages-writings-index-js": () => import("./../../../src/pages/writings/index.js" /* webpackChunkName: "component---src-pages-writings-index-js" */),
  "component---src-pages-writings-level-of-error-handling-js": () => import("./../../../src/pages/writings/level-of-error-handling.js" /* webpackChunkName: "component---src-pages-writings-level-of-error-handling-js" */),
  "component---src-pages-writings-naming-is-hard-js": () => import("./../../../src/pages/writings/naming-is-hard.js" /* webpackChunkName: "component---src-pages-writings-naming-is-hard-js" */),
  "component---src-pages-writings-signing-up-for-ironman-js": () => import("./../../../src/pages/writings/signing-up-for-ironman.js" /* webpackChunkName: "component---src-pages-writings-signing-up-for-ironman-js" */),
  "component---src-pages-writings-software-bugs-js": () => import("./../../../src/pages/writings/software-bugs.js" /* webpackChunkName: "component---src-pages-writings-software-bugs-js" */),
  "component---src-pages-writings-t-shaped-engineer-js": () => import("./../../../src/pages/writings/t-shaped-engineer.js" /* webpackChunkName: "component---src-pages-writings-t-shaped-engineer-js" */),
  "component---src-pages-writings-training-for-ironman-js": () => import("./../../../src/pages/writings/training-for-ironman.js" /* webpackChunkName: "component---src-pages-writings-training-for-ironman-js" */),
  "component---src-pages-writings-why-chrome-js": () => import("./../../../src/pages/writings/why-chrome.js" /* webpackChunkName: "component---src-pages-writings-why-chrome-js" */),
  "component---src-pages-writings-why-software-projects-are-notoriously-late-js": () => import("./../../../src/pages/writings/why-software-projects-are-notoriously-late.js" /* webpackChunkName: "component---src-pages-writings-why-software-projects-are-notoriously-late-js" */)
}

